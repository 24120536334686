import { Citation } from "../types/types";
import { handleError } from "./handleError";
import { checkIfStringHasContent } from "./utils";

// https://www.30secondsofcode.org/js/s/replace-last-occurrence/
const replaceLast = (str: string, pattern: string | RegExp, replacement: string): string => {
    const spaceCharacter = "‎";
    const match =
        typeof pattern === 'string'
            ? pattern
            : (str.match(new RegExp(pattern.source, 'g')) || []).slice(-1)[0];
    if (!match) return str;
    const last = str.lastIndexOf(match);
    return last !== -1
        ? `${str.slice(0, last)}${spaceCharacter}${replacement}${spaceCharacter}${str.slice(last + match.length)}`
        : str;
};

const formatCitationText = (text: string): string => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const hasBoldMarkdown = boldRegex.test(text);

    const replacedText = (hasBoldMarkdown ? text : text.replaceAll('*', ''));
    return replacedText
}

export function embedCitationsV3(props: { text: string, citations: Citation[], isComplete: boolean }): string {
    const { text, citations } = props;
    try {
        if (!checkIfStringHasContent(text)) return text

        const citationsCopy = [...citations];
        const input = text;
        let pointer = text.length;
        const orderedCitations = citationsCopy.sort((a, b) => Number(a.start) - Number(b.start));
        // show dot cursor iff answer is still streaming
        // n.b. citations are loaded after answer streamed
        const cursor = `<Cursor show={${props.isComplete === false && !citations.length}} />`;
        if (!text) return `${cursor}`;
        if (!citations || citations.length === 0) return `${text}${cursor}`;

        const chartIds = text.match(chartRegex)

        let mutableResult = input;
        for (let i = orderedCitations.length; i > 0; i--) {

            const citation = orderedCitations[i - 1];
            if (chartIds?.find((v) => v.includes(citation.text))) {
                continue;
            }
            const pre = citation.text && formatCitationText(citation.text)
            // highlights aren't parsing correctly
            const post = `<Cite citation={${JSON.stringify({ ...citation, highlights: [] })}}>${pre}</Cite>`;
            const left = mutableResult.substring(0, pointer);
            const right = mutableResult.substring(pointer, mutableResult.length);
            mutableResult = `${replaceLast(left, pre, post)}${right}`;
            pointer = citation.start;
        }
        // adding <span> prefix breaks tables in some cases
        const result = `${mutableResult}${cursor}`
        // ``` markdown isn't rendered correctly so we have to replace it here
        const formattedResult = result.replace(/```([^`]*)```/g, '<pre>$1</pre>');
        return formattedResult
    } catch (e) {
        handleError(e)
        return text;
    }
}

export const chartRegex = /!\[((?:[^\[\]]|\[[^\[\]]*\])*)\]\(((?:[^()]|\([^()]*\))*)\)/g;

export const embedCharts = ({ text }: { text: string }) => {
    return text.replaceAll(chartRegex, (_, _title, graphName) => {
        // Remove any leading/trailing whitespace from graphName
        const cleanGraphName: string = graphName.trim();
        if (cleanGraphName.startsWith('chart')) {
            return `<Chart id={${cleanGraphName}} />`;
        } else {
            return `<Table id={${cleanGraphName}} />`;
        }
    });
}

export const getChartIds = (text: string): string[] => {
    const graphNames: string[] = [];
    let match;

    while ((match = chartRegex.exec(text)) !== null) {
        const graphName = match[2].trim();
        if (graphName) {
            graphNames.push(graphName);
        }
    }

    return graphNames;
};

export const embedCitationsV4 = (text: string) => {
    const regex = /\[\d+\]/g
    const matches = text.match(regex)
    const cleanedText = text.replaceAll(regex, '')
    let embeddedText = cleanedText

    const uniqueMatches = Array.from(new Set(matches)).sort((a, b) => {
        const numberAInsideBrackets = a.slice(1, a.length - 1)
        const numberBInsideBrackets = b.slice(1, b.length - 1)

        return parseInt(numberAInsideBrackets) - parseInt(numberBInsideBrackets)
    })

    uniqueMatches?.forEach((match) => {
        const numberInsideBrackets = match.slice(1, match.length - 1)
        embeddedText = `${embeddedText} <Citation id={${numberInsideBrackets}} />`
    });

    return embeddedText
}

export const getCitationIds = (text: string) => {
    const regex = /\[\d+\]/g
    const matches = text.match(regex)

    const uniqueMatches = Array.from(new Set(matches)).sort((a, b) => {
        const numberAInsideBrackets = a.slice(1, a.length - 1)
        const numberBInsideBrackets = b.slice(1, b.length - 1)

        return parseInt(numberAInsideBrackets) - parseInt(numberBInsideBrackets)
    })

    return uniqueMatches.map((match) => parseInt(match.slice(1, match.length - 1)))
}