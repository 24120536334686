import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useNavigate } from "react-router";
import { captialise, checkDesiaUser, checkIntegrationFlag, handleLogout } from "../../utils/utils";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "@/contexts/UserContext";
import { TypographyBody } from "../ui/Typography";
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext";
import { Button } from "../ui/button";
import { EllipsisVertical } from "lucide-react";
import { SettingsDialog } from "./SettingsDialog";
import { useSearchParams } from "react-router-dom";
import { SettingsTab } from "@/types/types";

export function UserMenu({ showMenu }: { showMenu: boolean }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [settingsTab, setSettingsTab] = useState<SettingsTab | null>(null)

  const navigate = useNavigate();
  const user = useContext(UserContext);
  const ff = useContext(FeatureFlagContext);
  const isDesiaUser = checkDesiaUser(user.user);

  const shouldShowIntegrations = checkIntegrationFlag(ff, user.user)

  const Profile = () => (
    <span
      className="flex items-center gap-2 rounded-lg transition-all hover:text-primary focus:outline-none"
    >
      <img src={user?.user?.picture} className="h-8 w-8 rounded-full" />
      <TypographyBody isStrong={true} className="truncate">{captialise(user.user?.nickname || '')}</TypographyBody>
    </span>
  )

  useEffect(() => {
    if (searchParams.get('msft-user-registration')) {
      setSettingsTab(SettingsTab.APPS)

      setSearchParams(v => {
        v.delete('msft-user-registration')
        return v
      })
    }
  }, [searchParams, setSearchParams])

  if (!showMenu) return Profile()

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex gap-2 items-center">
            {Profile()}

            {showMenu && (
              <Button variant='tertiary' size='icon'>
                <EllipsisVertical className="size-6 shrink-0 stroke-[1.5px]" />
              </Button>
            )}
          </div>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end" className="w-[160px]">
          {shouldShowIntegrations && (
            <>
              <DropdownMenuItem
                onClick={() => {
                  navigate("/integrations");
                }}
              >Integrations
              </DropdownMenuItem>
            </>
          )}
          {isDesiaUser && (
            <DropdownMenuItem
              onClick={() => {
                setSettingsTab(SettingsTab.PROFILE)
              }}
            >Settings
            </DropdownMenuItem>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              handleLogout(navigate)
            }}
          >Sign out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <SettingsDialog
        initialTab={settingsTab || undefined}
        open={Boolean(settingsTab)}
        setOpen={(v) => {
          if (v) {
            setSettingsTab(SettingsTab.PROFILE)
          } else {
            setSettingsTab(null)
          }
        }}
      />
    </>

  )
}