import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Dialog, DialogBody, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog"
import { captialise, checkUserFlag, friendlyOrgName, getIntegrationName } from "@/utils/utils"
import { Button } from "../ui/button"
import { TypographyBody } from "../ui/Typography"
import { UserContext } from "@/contexts/UserContext"
import { UserControlledFeatureFlags } from "../UserControlledFeatureFlags"
import { AssistantConfig } from "../AssistantConfig"
import { SettingsConnectedAppsDialog } from "./SettingsConnectedAppsDialog"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { fetchUserIntegrations } from "../Integration/integrationThunk"
import { IntegrationCode, SettingsTab, UserIntegration } from "@/types/types"
import { IntegrationSettingsCard } from "../Integration/IntegrationSettingsCard"
import { DialogToast } from "../ui/toast"
import { isFulfilled } from "@reduxjs/toolkit"
import { ToastContext } from "@/contexts/ToastContext"
import { LocalStorageKey } from "@/constants"

const SettingsConnectedApps = () => {
    const userIntegrations = useSelector((state: RootState) => state.integration.userIntegrations)

    const { showToast } = useContext(ToastContext)

    const [showDialog, setShowDialog] = useState(false)
    const [dialogSource, setDialogSource] = useState<UserIntegration | null>(null)

    const dispatch = useDispatch<AppDispatch>()

    const personalIntegrations = useMemo(() => userIntegrations.data?.filter((v) => v.integration_code_name === IntegrationCode.OUTLOOK || v.integration_code_name === IntegrationCode.TEAMS) || [], [userIntegrations])

    const handleRefresh = useCallback(async () => {
        const result = await dispatch(fetchUserIntegrations())
        if (isFulfilled(fetchUserIntegrations)(result)) {
            const newIntegrations = result.payload.integrations
            const connectingIntegration = localStorage.getItem(LocalStorageKey.CONNECTING_INTEGRATION)

            if (connectingIntegration && newIntegrations.some((v) => v.integration_code_name === connectingIntegration && v.integration_enabled_by_user)) {
                showToast({
                    variant: 'success',
                    description: `${getIntegrationName(connectingIntegration)} has been connected`,
                    toastId: 'settings-dialog',
                    dismissable: true,
                }, 5000)

                localStorage.removeItem(LocalStorageKey.CONNECTING_INTEGRATION)
            }
        }

    }, [dispatch, showToast])

    useEffect(() => {
        dispatch(fetchUserIntegrations())
    }, [dispatch])

    useEffect(() => {
        handleRefresh()
    }, [handleRefresh])

    return (
        <>
            <div className="flex flex-col gap-6">
                {personalIntegrations?.map((integration) => {
                    return (
                        <IntegrationSettingsCard
                            key={`integration-settings-${integration.integration_code_name}`}
                            integration={integration}
                            onClick={() => {
                                setDialogSource(integration)
                                setShowDialog(true)
                            }}
                        />
                    )
                })}
            </div>
            {dialogSource && (
                <SettingsConnectedAppsDialog open={showDialog} setOpen={setShowDialog} integration={dialogSource} />
            )}
        </>
    )
}

export const SettingsDialog = ({ initialTab, open, setOpen }: { initialTab?: SettingsTab, open: boolean, setOpen: (open: boolean) => void }) => {
    const { user } = useContext(UserContext);

    const [selectedTab, setSelectedTab] = useState<SettingsTab>(SettingsTab.PROFILE)

    const tabs = [SettingsTab.PROFILE, SettingsTab.SETTINGS, SettingsTab.APPS]

    const orgId = user?.app_metadata.organization_id
    const orgName = friendlyOrgName(orgId)
    const modelParametersEnabled = checkUserFlag('assistant: model parameters')

    useEffect(() => {
        if (initialTab) {
            setSelectedTab(initialTab)
        }
    }, [initialTab])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="tablet:min-w-[1000px] min-w-[calc(100vw-80px)]">
                <DialogHeader>
                    <DialogTitle>Settings</DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="flex gap-0 h-[480px] mt-12">
                        <div className="flex flex-col w-[220px] pr-3 gap-3 border-r border-system-border-light">
                            {tabs.map((tab) => {
                                return (
                                    <div
                                        className={`px-3 py-1 h-fit w-fit border-l-2 border-b-0 ${selectedTab === tab ? 'border-system-primary' : 'border-transparent'}`}
                                        onClick={() => {
                                            setSelectedTab(tab)
                                        }}
                                        key={tab}
                                    >
                                        <Button variant="tertiary" className="w-full">
                                            <TypographyBody isStrong={selectedTab === tab} className={`w-full text-left ${selectedTab === tab ? 'text-system-primary' : 'text-system-body'}`}>
                                                {captialise(tab)}
                                            </TypographyBody>
                                        </Button>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="w-full h-[480px] px-6 overflow-y-auto">
                            {selectedTab === SettingsTab.PROFILE && (
                                <div className="grid grid-cols-2 gap-6 w-full h-fit">
                                    <div className="flex flex-col w-full h-fit gap-0 p-4 rounded-lg border border-system-border-light bg-system-surface-light">
                                        <TypographyBody isStrong>
                                            Name
                                        </TypographyBody>
                                        <TypographyBody className="text-system-body line-clamp-1 break-all">
                                            {user?.nickname || ''}
                                        </TypographyBody>
                                    </div>

                                    <div className="flex flex-col w-full h-fit gap-0 p-4 rounded-lg border border-system-border-light bg-system-surface-light">
                                        <TypographyBody isStrong>
                                            E-mail
                                        </TypographyBody>
                                        <TypographyBody className="text-system-body line-clamp-1 break-all">
                                            {user?.email || ''}
                                        </TypographyBody>
                                    </div>

                                    <div className="flex flex-col w-full h-fit gap-0 p-4 rounded-lg border border-system-border-light bg-system-surface-light">
                                        <TypographyBody isStrong>
                                            Organisation
                                        </TypographyBody>
                                        <TypographyBody className="text-system-body line-clamp-1 break-all">
                                            {orgName}
                                        </TypographyBody>
                                    </div>

                                    <div className="flex flex-col w-full h-fit gap-0 p-4 rounded-lg border border-system-border-light bg-system-surface-light">
                                        <TypographyBody isStrong>
                                            User ID
                                        </TypographyBody>
                                        <TypographyBody className="text-system-body line-clamp-1 break-all">
                                            {user?.sub || ''}
                                        </TypographyBody>
                                    </div>
                                </div>
                            )}

                            {selectedTab === SettingsTab.SETTINGS && (
                                <div className="flex flex-col gap-2">
                                    <TypographyBody isStrong>Feature flags</TypographyBody>

                                    <div className="p-4 rounded-lg border border-system-border-light bg-system-surface-light">
                                        <UserControlledFeatureFlags />
                                    </div>
                                    {modelParametersEnabled && (
                                        <>
                                            <TypographyBody isStrong className="mt-4">Model parameters</TypographyBody>
                                            <div className="p-4 rounded-lg border border-system-border-light bg-system-surface-light">
                                                <AssistantConfig />
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}

                            {selectedTab === SettingsTab.APPS && (
                                <SettingsConnectedApps />
                            )}
                        </div>
                    </div>
                </DialogBody>
                <DialogToast id="settings-dialog" />
            </DialogContent>
        </Dialog>
    )
}