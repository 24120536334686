import { ArrowLeft, ArrowRight } from "lucide-react"
import { Button } from "../ui/button"
import { TypographyBody, TypographyH4, TypographyLabel } from "../ui/Typography"
import { useContext, useEffect, useRef, useState } from "react"
import { AppDispatch, RootState } from "@/store/store"
import { useDispatch, useSelector } from "react-redux"
import { Input } from "../ui/input"
import Divider from "../ui/divider"
import { FileTable } from "../Integration/FileTable"
import { DocgenSession, DocgenStep, DocgenTemplate, FileStructure, QueryStatus } from "@/types/types"
import { transformResponseDocuments } from "@/utils/transformResponseDocuments"
import { listDocuments } from "../Document/documentThunk"
import { CustomAlert } from "../CustomAlert"
import { createSession, createTheme } from "./docGenThunk"
import { useNavigate } from "react-router-dom"
import { isFulfilled } from "@reduxjs/toolkit"
import { ToastContext } from "@/contexts/ToastContext"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form, FormControl, FormField, FormItem, FormMessage } from "../ui/form"
import { fetchSession } from "./docGenThunk"

export const DocGenTopic = ({ template, onPrevClick, onNextClick, onSessionCreate, onTopicChange, onNavigateToTopic, session }: { template: DocgenTemplate | null, onPrevClick: () => void, onNextClick: () => void, onSessionCreate: (session: DocgenSession) => void, onTopicChange: (topic: string) => void, onNavigateToTopic: () => void, session: DocgenSession | null }) => {
    const documentStore = useSelector((state: RootState) => state.document)
    const { showToast } = useContext(ToastContext)

    const [searchText, setSearchText] = useState('')
    const [elements, setElements] = useState<FileStructure[]>([])
    const [filteredElements, setFilteredElements] = useState<FileStructure[]>([])
    const [tempSelectedElements, setTempSelectedElements] = useState<FileStructure[]>([])
    const [noSelectionError, setNoSelectionError] = useState(false)

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const formRef = useRef<HTMLFormElement>(null)

    const loading = documentStore.fetchStatus === QueryStatus.FETCHING;
    const errorFetching = documentStore.fetchStatus === QueryStatus.ERROR_FETCHING;

    const formSchema = z.object({
        topic: z.string().min(1, 'Please enter a topic'),
    })

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            topic: ''
        },
    })

    useEffect(() => {
        const readyResources = documentStore.files.filter((v) => v.document_is_ready_to_use)

        setElements(transformResponseDocuments(readyResources))
    }, [documentStore.files])

    useEffect(() => {
        const filteredDocuments = documentStore.files.filter((doc) => {
            const isPdf = doc.document_type_friendly === 'PDF'
            if (!searchText) { return isPdf }
            const splitText = searchText.toLowerCase().split(' ')
            const parents = doc.document_source_path_treeview || []
            const result = splitText.some((text) => doc.document_name.toLowerCase().includes(text)) || splitText.some((text) => parents.some((v) => v.element_name.toLowerCase().includes(text)))
            return result && isPdf
        })

        const elements = transformResponseDocuments(filteredDocuments)
        setFilteredElements(elements)
    }, [documentStore.files, searchText, tempSelectedElements])

    useEffect(() => {
        const selected = getSelectedElements(elements)

        setTempSelectedElements(selected)
    }, [elements])

    useEffect(() => {
        if (documentStore.fetchStatus === QueryStatus.INITIALISED) {
            dispatch(listDocuments())
        }
    }, [])

    useEffect(() => {
        const fetchAndSetSession = async () => {
            if (session) {
                try {
                    // TODO: show loading while this fetches
                    const result = await dispatch(fetchSession(session.id))

                    if (isFulfilled(fetchSession)(result)) {
                        form.reset({ topic: result.payload.topic || '' })

                        setElements((prev) =>
                            prev.map((elem) => ({
                                ...elem,
                                is_included: result.payload.files?.includes(elem.document_id!) || false
                            })))
                    }
                } catch (error) {
                    console.error('Error fetching session:', error)
                }
            }
        }

        fetchAndSetSession()
    }, [session, form, setElements])

    const getSelectedElements = (elements: FileStructure[]): FileStructure[] => {
        let includedElements = []

        includedElements = elements.filter((v) => {
            return v.is_included && v.element_type === 'file'
        })

        return includedElements
    }

    const generateOutline = async (topic: string) => {
        if (!template || !template.id || !template.title) return

        const documentIds = tempSelectedElements.map((v) => v.document_id).filter((v): v is string => Boolean(v))

        const result = await dispatch(createSession({
            topic: topic,
            template_id: template.id,
            title: template.title,
            files: documentIds,
        }))

        if (isFulfilled(createSession)(result)) {
            const session = result.payload as DocgenSession
            onSessionCreate(session)
            if (session.id) {
                await dispatch(createTheme(session.id))
                navigate(`/docgen/${session.id}?step=${DocgenStep.OUTLINE}`)
            }
        } else {
            showToast({
                variant: 'error',
                description: `We were unable to save your progress. Please try again by clicking 'Continue'`,
                dismissable: true
            })

            onNavigateToTopic()
        }
    }

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        if (tempSelectedElements.length === 0) {
            setNoSelectionError(true)
            return
        }
        onTopicChange(values.topic)
        setNoSelectionError(false)
        generateOutline(values.topic)
        onNextClick()
    }

    return (
        <>
            <div className="flex flex-col gap-[54px] w-[1000px] mx-auto">
                <div className="flex flex-col gap-1 text-center mx-auto">
                    <TypographyH4>
                        Define topic and sources
                    </TypographyH4>

                    <TypographyBody className="text-system-body whitespace-pre-wrap">
                        {'Pick the topic, the subject of the report, and choose\nwhich files to use as sources'}
                    </TypographyBody>
                </div>

                <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4 w-[400px]">
                        <Form {...form}>
                            <form ref={formRef} onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
                                <div className="mx-auto space-y-8 overflow-x-visible ">
                                    <FormField
                                        control={form.control}
                                        name="topic"
                                        render={({ field }) => (
                                            <FormItem className="space-y-2">
                                                <div className="flex flex-col gap-0">
                                                    <TypographyBody isStrong>
                                                        Define topic
                                                    </TypographyBody>
                                                    <TypographyLabel className="text-system-body">
                                                        The topic can be a company, industry, trend,...
                                                    </TypographyLabel>
                                                </div>
                                                <FormMessage className="w-fit min-w-[400px]" />
                                                <FormControl >
                                                    <Input {...field} className="w-[400px]" placeholder="e.g. TechCorp" />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </form>
                        </Form>
                    </div>

                    <Divider />

                    <div className="flex flex-col gap-8">
                        <div className="flex flex-col gap-0">
                            <TypographyBody isStrong>
                                Select sources from Library
                            </TypographyBody>
                            <TypographyLabel className="text-system-body whitespace-pre-wrap">
                                Selected files will be used to generate content of the report.
                            </TypographyLabel>
                        </div>
                        {noSelectionError && (
                            <CustomAlert
                                variant="error"
                                description="Please select files as sources before continuing"
                            />
                        )}

                        <div className="flex flex-col gap-8">
                            {tempSelectedElements.length > 0 && (
                                <div className="flex flex-col gap-6 p-4 border border-system-border-light bg-system-surface-light rounded-lg mb-4">
                                    <TypographyBody isStrong className="text-system-primary">
                                        Selected files
                                    </TypographyBody>

                                    <div className="overflow-y-auto">
                                        <FileTable
                                            elements={elements}
                                            shownElements={tempSelectedElements}
                                            resources={documentStore.files}
                                            setElements={(v) => {
                                                setElements(v)
                                            }}
                                            showCheckbox={true}
                                            showHeader={true}
                                            type='ask'
                                            loading={loading}
                                            error={errorFetching}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="mx-auto">
                                <Input
                                    className="w-[320px]"
                                    placeholder="Search in library to select"
                                    isSearch={true}
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value)
                                    }}
                                    isCloseVisible={!!searchText}
                                    onCloseClick={() => setSearchText('')}
                                />
                            </div>
                            <div className="overflow-y-auto pb-[56px]">
                                <FileTable
                                    elements={elements}
                                    shownElements={filteredElements}
                                    resources={documentStore.files}
                                    setElements={(v) => {
                                        setElements(v)
                                    }}
                                    showCheckbox={true}
                                    showHeader={true}
                                    type='ask'
                                    loading={loading}
                                    error={errorFetching}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="fixed flex flex-col gap-4 h-fit w-fit p-4 bg-system-surface rounded-lg border border-system-border-regular shadow-outline-dialog bottom-10 left-[50%] -translate-x-[50%]">

                <div className="flex gap-2 mx-auto">
                    <Button variant='secondary' onClick={onPrevClick}>
                        <div className="flex gap-2 items-center">
                            <ArrowLeft className="size-6 shrink-0 stroke-[1.5px]" />

                            Back
                        </div>
                    </Button>

                    <Button onClick={() => {
                        formRef.current?.requestSubmit()
                    }}>
                        <div className="flex gap-2 items-center">
                            Continue

                            <ArrowRight className="size-6 shrink-0 stroke-[1.5px]" />
                        </div>
                    </Button>
                </div>
            </div>
        </>

    )
}