import { WEB_SERVER_ENDPOINT } from "@/constants";
import { RootState } from "@/store/store";
import { DocgenSession, DocgenTemplate, DocgenTemplateRequest, DocgenTheme } from "@/types/types";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchTemplates = createAsyncThunk<
    DocgenTemplate[],
    void,
    { state: RootState }
>(
    'docGen/fetchTemplates',
    async (_, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/template/list`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    }
)

export const fetchTemplate = createAsyncThunk<
    DocgenTemplate,
    string,
    { state: RootState }
>(
    'docGen/fetchTemplate',
    async (templateId, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/template/${templateId}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    }
)

export const createTemplate = createAsyncThunk<
    DocgenTemplateRequest,
    DocgenTemplateRequest,
    { state: RootState }
>('docGen/createTemplate', async (template, { rejectWithValue }) => {
    try {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/template`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(template)
        });
        const data = await response.json()
        if (!response.ok) {
            throw new Error(`Failed to create template: ${response.statusText}`);
        }
        return data
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
        return rejectWithValue('An unknown error occurred');
    }
});

export const updateTemplate = createAsyncThunk<
    DocgenTemplate,
    DocgenTemplate,
    { state: RootState }
>('docGen/updateTemplate', async (template, { rejectWithValue }) => {
    try {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/template/${template.id}`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(template)
        });
        const data = await response.json()
        if (!response.ok) {
            throw new Error(`Failed to update template: ${response.statusText}`);
        }
        return data
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
        return rejectWithValue('An unknown error occurred');
    }
});

export const deleteTemplate = createAsyncThunk<
    void,
    string,
    { state: RootState }
>('docGen/deleteTemplate', async (templateId, { rejectWithValue }) => {
    try {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/template/${templateId}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        if (!response.ok) {
            throw new Error(`Failed to delete template: ${response.statusText}`);
        }
        return
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
        return rejectWithValue('An unknown error occurred');
    }
});

export const createTemplateFromDocument = createAsyncThunk<
    DocgenTemplate,
    string,
    { state: RootState }
>('docGen/createTemplateFromDocument', async (documentId, { rejectWithValue }) => {
    try {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/template/generate-from-file/${documentId}`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            throw new Error(`Failed to create template from document: ${response.statusText}`);
        }
        return data
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
        return rejectWithValue('An unknown error occurred');
    }
});

export const fetchSessions = createAsyncThunk<
    DocgenSession[],
    void,
    { state: RootState }
>(
    'docGen/fetchSessions',
    async (_, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/session/list`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    }
)

export const fetchSession = createAsyncThunk<
    DocgenSession,
    string,
    { state: RootState }
>(
    'docGen/fetchSession',
    async (sessionId, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/session/${sessionId}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    }
)

export const createSession = createAsyncThunk<
    DocgenSession,
    Omit<DocgenSession,
        | 'id'
        | 'status'>,
    { state: RootState }
>(
    'docGen/createSession',
    async (session, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/session`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(session)
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    }
)

export const createTheme = createAsyncThunk<
    {
        message: string,
        theme: DocgenTheme
    },
    string,
    { state: RootState }
>(
    'docGen/createTheme',
    async (sessionId, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/theme/generate`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                session_id: sessionId
            })
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    }
)

export const fetchThemes = createAsyncThunk<
    DocgenTheme[],
    void,
    { state: RootState }
>(
    'docGen/fetchThemes',
    async (_, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/theme/list`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    }
)

export const deleteSession = createAsyncThunk<
    void,
    string,
    { state: RootState }
>('docGen/deleteSession', async (sessionId, { rejectWithValue }) => {
    try {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/session/${sessionId}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        if (!response.ok) {
            throw new Error(`Failed to delete session: ${response.statusText}`);
        }
        return
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
        return rejectWithValue('An unknown error occurred');
    }
})

export const fetchTheme = createAsyncThunk<
    DocgenTheme,
    string,
    { state: RootState }
>(
    'docGen/fetchTheme',
    async (themeId, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/theme/${themeId}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    }
)

export const updateTheme = createAsyncThunk<
    DocgenTheme,
    DocgenTheme,
    { state: RootState }
>('docGen/updateTheme', async (theme, { rejectWithValue }) => {
    try {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/theme/${theme.id}`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(theme)
        });
        const data = await response.json()
        if (!response.ok) {
            throw new Error(`Failed to update template: ${response.statusText}`);
        }
        return data
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
        return rejectWithValue('An unknown error occurred');
    }
});


export const createDraft = createAsyncThunk<
    void,
    string,
    { state: RootState }
>(
    'docGen/createDraft',
    async (sessionId, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/docgen/session/generate/draft`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                session_id: sessionId
            })
        });
        if (!response.ok) {
            return rejectWithValue({ message: response.statusText })
        }
        return
    }
)